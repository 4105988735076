import React from "react"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import SEO from "../../../../components/seo"
import CookieMobileUkPolicy from "../../../../components/body/pages/mobile/uk/legal/cookie"

const CookieUkPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-gb/legal/cookie-policy/"}
      title="Cookie Policy | Kuda | The Money App for Africans"
    />
    <CookieMobileUkPolicy />
  </NoheaderFooter>
)

export default CookieUkPage
